.about .list-wrap {
  padding: 20px 40px;
  background-color: #fff;
  font-family: Roboto;
}
.about .list-wrap .help {
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 34px;
  text-align: left;
  color: #164a78;
}
.about .list-wrap .steps {
  margin-top: 30px;
  margin-bottom: 30px;
}
.about .list-wrap .steps .stepTop {
  padding: 0 107px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about .list-wrap .steps .stepTop .circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 60px;
  text-align: left;
  background: #164a78;
  text-align: center;
  color: #fff;
}
.about .list-wrap .steps .stepTop .line {
  width: 33%;
  height: 1px;
  border: 1px dashed black;
}
.about .list-wrap .steps .stepValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about .list-wrap .steps .stepValue .title {
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 34px;
  color: #164a78;
}
.about .list-wrap .steps .stepValue .description {
  margin-top: 10px;
  font-size: 18px;
  font-family: Roboto;
  color: #989292;
}
.about .list-wrap .header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.about .list-wrap .contentBuyer {
  flex: 1;
  padding: 10px;
}
.about .list-wrap .contentBuyer p {
  font-size: 16px;
  text-align: left;
  line-height: 30px;
  margin-bottom: 20px;
}
.about .list-wrap .contentBuyer p:nth-child(3) {
  margin-bottom: 0;
}
.about .list-wrap .contentBuyer p:nth-child(6) {
  margin-bottom: 0px;
}
.about .list-wrap .contentBuyer ul li {
  font-size: 16px;
  text-align: left;
  line-height: 30px;
  margin-left: 20px;
}
.about .list-wrap .contentBuyer ul li:nth-child(3) {
  margin-bottom: 20px;
}
.about .list-wrap .contentBuyer .help-item {
  margin-bottom: 10px;
}
.about .list-wrap .contentBuyer .help-item .index {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #164a78;
}
.help-modal {
  width: 800px !important;
}
.help-modal img {
  width: 350px;
  margin: 0 auto;
}
.help-modal .modal-body {
  text-align: center;
}
.help-modal .modal-body .content {
  text-align: left;
}
