html,
body,
p {
  margin: 0;
  line-height: 1.2;
}

html {
  min-width: 985px;
  overflow: scroll;
  --antd-wave-shadow-color: #164a79;
  /* transition: all 0.1s ease; */
}

body {
  margin: 0;
  /* font-family: "PingFang SC", "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: Arial,Helvetica,"Microsoft Yahei","Hiragino Sans GB","HeitiSC","WenQuanYi Micro Hei",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  display: none !important;
}
