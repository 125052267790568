.offlineMessage {
  font-family: Roboto;
}
.offlineMessage .title {
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
  color: #164a78;
}
.offlineMessage .content {
  line-height: 20px;
  margin-bottom: 20px;
}
.offlineMessage .contentTwo {
  margin-bottom: 20px;
}
.offlineMessage .contentTwo p {
  line-height: 26px;
}
.offlineMessage .remindMessage {
  color: blue;
  margin: 20px auto;
}
.offlineMessage .logo {
  width: 217px;
}
.offlineMessage .btn-wrap {
  text-align: center;
}
