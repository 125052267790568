.summary-content {
  padding: 20px;
  font-family: Roboto;
}
.summary-content a {
  text-decoration: underline;
}
.summary-content .summary-header {
  text-align: left;
}
.summary-content .summary-header p {
  padding-bottom: 15px;
}
.summary-content .summary-header p:nth-child(1) {
  font-size: 22px;
  font-weight: 700;
  color: #164A78;
}
.summary-content .summary-header-top {
  text-align: left;
}
.summary-content .summary-header-top span:nth-child(1) {
  font-size: 22px;
  font-weight: 700;
  color: #164A78;
  float: left;
}
.summary-content .summary-header-top span:nth-child(2) {
  font-size: 22px;
  font-weight: 700;
  color: #164A78;
  float: right;
}
.summary-content .summary-header-top span:nth-child(2) span {
  color: #F8CB4C;
}
.summary-content .summary-content-box {
  margin-top: 8%;
  height: 400px;
}
.summary-content .summary-content-box .summary-content-box-left {
  float: left;
  width: 30px;
  height: 50px;
  border: 2px solid;
  border-right: none;
  border-top: none;
  position: relative;
  top: -38px;
  left: 27px;
}
.summary-content .summary-content-box .summary-content-box-left-2 {
  border: 2px solid;
  height: 192px;
  width: 30px;
  float: left;
  border-right: none;
  border-top: none;
  position: relative;
  top: 6px;
  left: -3px;
}
.summary-content .summary-content-box .summary-content-box-right {
  float: right;
  width: 795px;
}
.summary-content .summary-content-box .summary-content-box-right .sub {
  margin-bottom: 5%;
}
.summary-content .summary-content-box .summary-content-box-right span:nth-child(1) {
  font-size: 22px;
  font-weight: 700;
  float: left;
}
.summary-content .summary-content-box .summary-content-box-right span:nth-child(2) {
  font-size: 22px;
  font-weight: 700;
  color: #164A78;
  float: right;
}
.summary-content .summary-content-box .summary-content-box-right span:nth-child(2) span {
  color: #F8CB4C;
}
.summary-content .summary-content-box .summary-content-box-right p {
  float: left;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table {
  border: 1px solid ;
  margin-top: 8%;
  height: 30px;
  padding: 7px;
  border-bottom: 1px dashed;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table p:nth-child(1) {
  float: left;
  font-weight: 700;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table p:nth-child(2) {
  float: right;
  font-weight: 700;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table2 {
  border: 1px solid ;
  border-bottom: 1px dashed;
  height: 30px;
  padding: 7px;
  border-top: none;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table2 p:nth-child(1) {
  float: left;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table2 p:nth-child(2) {
  float: right;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table3 {
  border: 1px solid ;
  border-top: none;
  height: 30px;
  padding: 7px;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table3 p:nth-child(1) {
  float: left;
}
.summary-content .summary-content-box .summary-content-box-right .summary-content-box-right-table3 p:nth-child(2) {
  float: right;
}
.summary-content .summary-content-box .summary-content-box-right .line {
  padding: 15px;
}
