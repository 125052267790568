.swiper-boxs {
  width: 100%;
  height: 600px;
}
.swiper-boxs img {
  width: 100%;
  height: 100%;
  object-fit: contain ;
}
.smallSwiper-boxs {
  width: 100%;
  height: 100px;
}
.smallSwiper-boxs img {
  object-fit: contain ;
}
.scorllBox {
  width: 80%;
  height: 170px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  /* 父级元素中的内容不换行 */
  margin: 30px auto;
}
.scorllBox .scorllBox-Item {
  width: 200px;
  height: 150px;
}
.scorllBox .yulanSmallImg {
  width: 200px;
  height: 150px;
  margin-right: 30px;
  cursor: pointer;
}
.scorllBox .swiperSmallBox {
  width: 100%;
  height: 100%;
}
.checkedItem {
  border: 1px solid red;
}
.scorllBox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
  border-radius: 10px;
}
.scorllBox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #929292;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.scorllBox::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.swiperBox {
  height: 550px;
  width: 100%;
}
.yulanImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
