@font-face { 
  font-family: Roboto; 
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
} 

@font-face {
  font-family: Roboto-Bold;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
}




.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  /* background-color: #14202b; */
  background: linear-gradient(#15222f, #151f2b);
  flex-direction: column;
  align-items: stretch;
}

.App.pin {
  height: 100vh;
  overflow: hidden;
}

.content-wrap {
  flex: 1;
  /* height: 100%; */
  background-image: url("./assets/image/app_bg.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* padding: 0 360px; */
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mt-30 {
  margin-top: 30px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
