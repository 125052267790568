.sto-apply {
  margin-top: 114px;
}
.sto-apply .tab-wrap {
  margin-top: 49px;
  text-align: right;
}
.sto-apply .content-wrap {
  background: #ffffff;
}
.sto-apply .title-flex {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sto-apply .title-flex .anticon {
  font-size: 30px;
}
.sto-apply .title-flex .ant-divider-dashed {
  border-color: #164a79;
  border-width: 4px 0 0;
  min-width: 50%;
}
.sto-apply .title-flex span {
  font-size: 18px;
  color: #164a79;
  padding: 5px;
}
.sto-apply .title-flex .left {
  margin-left: 10px;
}
