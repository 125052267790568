.portfolio-content {
  padding: 20px;
}
.portfolio-content .title-p {
  margin-left: -460px;
  margin-bottom: 1%;
}
.portfolio-content .ex-content {
  font-family: Roboto;
}
.portfolio-content .ex-content > .ex-content {
  font-size: 14px;
  font-weight: 400;
  color: #5E5E5E;
  line-height: 16px;
  text-align: left;
}
.portfolio-content .ex-content .token-have {
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: #164A78;
  line-height: 45px;
}
.portfolio-content .ex-content .token-have strong {
  color: #F8CB4C;
  margin-right: 10px;
}
.portfolio-content .pageTitle {
  font-size: 22px;
  font-family: Roboto;
  font-weight: bold;
  color: #164A78;
  line-height: 45px;
  text-align: left;
  margin-bottom: 20px;
}
.header-title {
  height: auto !important;
}
.table-box-items {
  cursor: pointer;
}
.table-box-items:hover {
  color: #164A78;
  border-bottom: 1px solid #164A78;
}
