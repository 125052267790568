.table-tr-box .ant-table-thead > tr > th,
.table-tr-box .ant-table-tbody > tr > td,
.table-tr-box .ant-table tfoot > tr > th,
.table-tr-box .ant-table tfoot > tr > td {
  padding-top: 4px;
  padding-bottom: 4px;
}
.token-wrap {
  padding: 20px 0 !important;
}
